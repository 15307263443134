//import react
import React from 'react'

function Featured_projects_heading() {
  return (
    <div className='featured-projects-heading'>
        <p>Featured Projects &#8650;</p>
    </div>
  )
}

export default Featured_projects_heading